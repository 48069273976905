/** @jsx jsx */
import { View } from '@bottlebooks/gatsby-design-system';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import { jsx } from 'theme-ui';
import CoverImage from '../CoverImage';

export default function EventBannerImage({
  event,
  children,
  className = undefined,
  withOverlay,
}) {
  const image = event?.mainImage;
  const name = event?.name;
  const hasImage = Boolean(image);
  return (
    <View
      sx={{
        minHeight: [105, null, 250],
        position: 'relative',
        color: hasImage ? 'onDark' : undefined,
        backgroundColor: 'nuanced',
      }}
      className={className}
    >
      {hasImage && (
        <CoverImage
          fluid={image.fluid}
          alt={name}
          loading="eager"
          withOverlay={withOverlay}
          sx={{ position: 'absolute' }}
        />
      )}
      {children}
    </View>
  );
}

export const fragment = graphql`
  fragment bb_EventBannerImage on Bottlebooks_Event {
    name
    mainImage {
      fluid(maxWidth: 1280, maxHeight: 360, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
  fragment EventBannerImage on Event {
    name
    mainImage {
      fluid(maxWidth: 1280, maxHeight: 360, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
`;
EventBannerImage.fragment = gql`
  fragment EventBannerImage on Event {
    name
    mainImage {
      fluid(maxWidth: 1280, maxHeight: 360, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
`;
