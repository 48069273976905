/** @jsx jsx */
import Image from 'gatsby-image';
import { jsx } from 'theme-ui';
import cssOverlay from '@bottlebooks/gatsby-theme-event/src/helpers/cssOverlay';

export default function CoverImage({ fluid, alt, withOverlay, ...rest }) {
  if (!fluid) return null;
  return (
    <Image
      fluid={fluid}
      alt={alt}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        img: { objectFit: 'cover' },
        '::after': withOverlay ? cssOverlay() : undefined,
      }}
      style={{ position: null, width: null, height: null }}
      placeholderStyle={{ objectFit: null, filter: 'blur(10px)' }}
      {...rest}
    />
  );
}
